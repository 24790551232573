const translationZH = {
    "nav": {
        "Home": "主页",
        "Philosophy": "理念",
        "Product": "服务",
        "Reviews": "患者评语",
        "Contact": "咨询",
        "FAQ": "常见问题",
        "PDF": "手册下载"
    },
    "philosophy": {
        "Founder": "筋骨还原创始人",
        "Name": "姓 名 ：谢 易 峰 医 师",
        "Description1": "敬天爱人 德才兼备 以德为先",
        "Description2": "是筋骨还原创办人主要的思想",
        "Description3": "想病人所想，急病人所急，",
        "Description4": "爱惜生命，关爱患者",
        "Para1": "易峰筋骨还原创始人谢易峰医师，原名谢伟龙出生于马来西亚雪邦，双溪比力，是中国，新加坡，马来西亚赫赫有名的中医师谢医师从小就不断的看到身边的人总是被病痛折磨，也发现每一个家庭里只要一个人失去健康，全家人陷入困境。。",
        "Para2": "于是就对着老天发愿了一个愿望，希望能成为一名医师，帮助每一个人获得健康但是。。当时家境面临经济困难的状况，想要出国学医需要很庞大资金，于是就边打工边存款存到资金后就开始出国学医。刚开始学了，整骨，推拿，针灸，铁打，chiropactic,，小颜术，拿到行医证后，就开始治疗患者那段期间，医好了很多病人，心情很喜悦，但是，随着病人越来越多，遇到的很多奇怪的杂症，一百个患者，只有七十个康复，而另外三十个就无法康复病情也开始恶化那个时候，谢易峰医师非常的失落。他一心想帮助这些痛苦的患者，但却无能为力，心情到了非常的低落，真心希望这三十个患者也可以得到康复，健康。",
        "Para3_title": "简介",
        "Para3_1": {
            "zh": "但是谢易峰医师不放弃，谢易峰医师深信，这个世界那么大，总会有一种治疗一定会让每一个人得到康复，健康的于是谢易峰医师将所有的储蓄，都用来不断的出国进修，哪怕是一个患者医师都想用尽所有的全力治好他，但那个时候。。。医师背负着庞大的学费问题，陷入了经济状况只能靠着面包和快熟面生活，家里人得知后，遭到家人的反对出国进修，面对着金钱的问题，面对着家人的反对，还遇到了课程收了昂贵的费用，却得不到任何有用的知识，煎熬的面临着生活的开销，只能白天打工，晚上上门做治疗赚取生活费，碎片时间就 看书学习就这样工作，吃饭，治疗，学习，反复着，后来可能是上天的慈悲吧，开始行好运了，慢慢的遇到好的老师，认识了很多名医，感恩老师们，加上经过很多的亲身临床经验和很多的名医指导后谢易峰医师发现了，原来我们每个人的身体都有一种规律，只要人的筋与骨还原到原本的位置，大部分的酸，痛，麻，都可获得大大的改善甚至包括内科疾病比如",
            "en_p1": "",
            "en_p2": "",
        },
        "Para3_2": "偏头痛",
        "Para3_3": "失眠",
        "Para3_4": "眩晕",
        "Para3_5": "胃",
        "Para3_6": "胸闷",
        "Para3_7": "，的等等,都会得到康复的效果。太棒了终于，经过一番的努力，帮助到了更多的人得到康复，花了不少心思后，于2015年谢易峰医师创办了",
        "Para3_8": "筋骨还原疗法",
        "Para3_9": "。",
        "Para3_10": "谢易峰医师经过了种种成就与经历",
        "Para3_11": "筋骨还原疗法",
        "Para3_12": "到至今已经帮助了超过",
        "Para3_13": "34000",
        "Para3_14": "名以上的患者得到了健康，最后也帮助了很多家庭得到和谐，一个人失去健康，全家人陷入困境，同样的，一个人获得健康，全家人幸福和谐 ，未来还会有更多人受益。",
    },

    "product": {
        "Concept": {
            "Label": "原理",
            "Title": "筋骨还原原理",
            "Para1": "筋骨还原是一种针对人体骨骼、肌肉、关节等组织的康复理疗方法，其原理主要是通过特定的手法和技巧，重建骨骼、肌肉和关节的生物力学平衡，从而达到缓解疼痛、促进康复的效果。",
            "Para2": "筋骨还原原理的基础是人体的骨骼、肌肉和关节是紧密相连的，它们之间的关系在人体的运动和功能方面起着至关重要的作用。如果骨骼、肌肉和关节之间存在生物力学的不平衡，就会导致疼痛、僵硬、失调等问题，影响身体的正常运动和功能。",
            "Para3": "筋骨还原的手法和技巧可以通过对身体的姿势、运动、生理状况等因素的综合考虑，针对不同的问题和症状，采用不同的调整手法，以重建骨骼、肌肉和关节的生物力学平衡，从而改善疼痛、增强运动能力、促进康复。",
            "Para4": "筋骨还原通过手法和技巧重建人体骨骼、肌肉和关节的生物力学平衡，从而达到缓解疼痛、促进康复的效果。",
        },

        "Theory": {
            "Label": "理论",
            "Title": "筋骨还原理论",
            "Para1": "筋骨还原理论是一种治疗身体不适和疼痛的方法，由马来西亚的一位名叫 谢易峰医师创立。",
            "Para2": "这种理论认为，身体各部分之间的筋膜、神经组织韧带、肌肉和骨骼是相互关联的，如果其中一个部分出现问题，就会对整个身体造成影响。",
            "Para3": "筋骨还原理论通过手法矫正身体各部分的关节，筋膜和脊椎骨，以达到改善身体功能、减轻疼痛的目的。这种手法通常使用脊椎仪器或手法在患者身上施加轻压或拉伸。",
            "Para4": "这种方法可以用于治疗各种疾病，如颈椎病、腰椎病、肩周炎、关节炎、坐骨神经、椎间盘突出，偏头痛、失眠、消化不良等等。",
            "Para5": "但需要注意的是，且需要由经过专业培训的筋骨还原医师或治疗师进行操作，以确保治疗效果和安全性。",
        },

        "Uniqueness": {
            "Label": "独特性",
            "Title": "筋骨还原独特性",
            "SubTitle1": "1. 非手术治疗",
            "SubTitle1_Para": "筋骨还原理论采用脊椎仪器或手法等进行治疗，不需要进行手术治疗，避免了手术风险和恢复期的痛苦。",
            "SubTitle2": "2. 个性化治疗",
            "SubTitle2_Para": "筋骨还原理论是针对个体进行治疗的，因为每个人的身体结构和问题不同，治疗方法也需要针对性地调整，因此可以根据患者的具体情况进行个性化的治疗，增加了治疗的有效性。",
            "SubTitle3": "3. 没有副作用",
            "SubTitle3_Para": "相对于其他药物治疗或手术治疗，筋骨还原理论的治疗方法较为温和，没有任何副作用，不会对患者的身体产生负面影响。",
            "SubTitle4": "4. 短期见效",
            "SubTitle4_Para": "部分疾病在筋骨还原理论治疗下可以迅速缓解症状，例如肌肉疼痛、关节僵硬等问题。",
            "SubTitle5": "5. 适用范围广",
            "SubTitle5_Para": "筋骨还原理论适用于多种疾病，包括颈椎病、腰椎病、肩周炎、关节炎、头痛、失眠、消化不良等等，因此可以应用于多个领域，满足患者不同的治疗需求。",
        },

        "cases": {
            "Title": "案例",
            "1": {
                "Title": "脊椎侧弯",
                "Content1": "患者：X-RAY报告医生说我患上了脊椎侧弯症，脊椎需要7万5500令吉手术费，当时我想到医生要用刀在我身上割开，我很害怕！",
                "Content2": "后来通过亲戚介绍，知道了易峰筋骨还原，经过5次的脊椎侧弯治疗，我的脊椎明显直了很多，也好看了很多。非常感谢筋骨还原治疗，让我在无需手术的情况下恢复正常的身体。",
            },
            "2": {
                "Title": "驼背",
                "Content": "妈妈：“我儿子驼背严重，两边肩膀往内缩，不抬头挺胸，一副驼背的样子，担心孩子担心儿子是不是有什么骨科疾病，不晓得会不会终身驼背。经过医师诊断后，确认这是结构性驼背，经过一次治疗后，驼背真的很明显直很多。",
            },
            "3": {
                "Title": "坐骨神经痛",
                "Content": "由于坐骨神经痛导致下背无力的症状无法下蹲，治疗后可以轻松蹲下了",
            },
            "4": {
                "Title": "面部骨骼",
                "Content1": "一直以来我觉得我的脸部很四方，经过医师的调整我的下额骨后，",
                "Content2": "我开始越来越喜欢我的脸部了，非常推荐！",
            },
            "5": {
                "Title": "五十肩",
                "Content1": "Tangan saya tidak dapat angkat tinggi kerana sangat sakit, dan saya tidak dapat ikat rambut saya.",
                "Content2": "Setelah treatment pertama, tangan saya sudah sembuh dan tidak sakit lagi.",
            },
            "6": {
                "Title": "膝关节炎",
                "Content": "我的膝盖疼了很久，走也疼，坐也疼。我去医院检查，医生让我开刀，我不要，后来朋友介绍我来这里做治疗，现在膝盖好了，我非常开心。",
            }
        }
    },

    "review": {
        "Title": "易峰筋骨还原",
        "1": {
            "Text1": "经过朋友的推荐，试试来医师这里治疗。长期的背疼让我无法睡好，做了几次治疗后疼痛明显改善。本身也因为曾经跌倒，盆骨高低导致长短脚问题，久站盆骨出现疼痛，治疗后也有明显改善。谢谢医师。",
        },
        "2": {
            "Text1": "经过谢易峰医师的治疗后，我的疼痛问题真的减少很多，尤其是肩膀酸痛，改善很多颈椎本来需要开刀的，现在也好起来了，不再紧绷，我很感谢谢易峰医师，让我恢复正常的日常生活。",
            "Text2": "本身也因为曾经跌倒，盆骨高低导致长短脚问题，久站盆骨出现疼痛，治疗后也有明显改善。谢谢医师。",
        },
        "3": {
            "Text1": "我已经痛苦一年了！X-Ray报告医生说我L1-L5有歪，曾经试过打3次止痛针，吃止痛药都没有好起来，通过Facebook知道了易峰筋骨还原医师，经过3次的治疗，我的腰酸背痛，小腿到脚裸到拇指麻痛的问题，就得到了明显的改善。",
            "Text2": "现在我的腰不酸痛了，小腿，拇指麻痹消失了，本来坐着半个小时就会酸疼麻痹，现在久坐都没有问题了。我非常感谢谢易峰医师，让我可以恢复正常的生活，不再痛苦。",
        },
        "4": {
            "Text1": "经过医师的治疗后，我的筋骨不会感觉拉扯，家人，朋友们都说我的身体已经挺直了很多，身体也不会向前倾，双脚也可以伸直了，以前睡觉必须卷着身体才能入睡，现在完全可以平躺睡着了，医师的治疗技术非常值得推荐，谢谢医师的治疗。",
        },
    },

    "contact": {
        "Title": "筋骨还原疗程步骤",

        "Consultation": "咨询",
        "Consultation_Descript": "点击WhatsApp，专业顾问将提供更详细的了解。",

        "Appointment": "预约",
        "Appointment_Descript1": "安排预约时间给医师诊断",
        "Appointment_Descript2": "无需长时间等候",

        "Diagnosis": "诊断",
        "Diagnosis_Descript": "问诊病情，进行健康检查，或诊断疾病。",

        "Explanation": "讲解",
        "Explanation_Descript": "向患者详细讲解，身体健康状况以及筋骨还原治疗的过程。",

        "Cost": "费用",
        "Cost_Descript": "根据个性化的治疗方案，让患者能够清晰了解自己需要的支付费用。提供合理透明的价格信息。",

        "Treatment": "治疗",
        "Treatment_Descript": "根据患者的病情和诊断结果，因为每个人的身体结构和问题不同，医师定制个性化治疗方案进行治疗，达到预期的治疗效果。",

        "Follow_up": "后续",
        "Follow_up_Descript": "根据治疗效果和患者的反应，进行后续观察和治疗，包括调整治疗计划、给予康复指导和建议等。",
    },

    "faq": {
        "Title": "常见问题",
        "1": {
            "Question": "我去筋骨还原看病之前需要预约吗？",
            "Ans1": "需要，您需要安排预约。请打电话或WhatsApp联系我们。我们将为您指派一位筋骨还原医疗顾问，他或她将在预约的各个步骤中为您提供协助。",
            "Ans2": "在您到达之前，指定的顾问将尽力安排好您需要的所有检查和程序。",
        },
        "2": {
            "Question": "预约需要多久？",
            "Ans": "我们将尽可能以及尽快帮助您安排预约时间。您的预约日期将取决于您的筋骨还原医师的时间以及您自己的需求和偏好。",
        },
        "3": {
            "Question": "我可以申请预约想见的医师吗？",
            "Ans": "可以。欢迎您申请预约特定的医师。您可以放心，该筋骨还原专家将为您的病症提供权威的专业知识。",
        },
        "4": {
            "Question": "我需要向筋骨还原提供我的医疗记录吗？",
            "Ans": "需要—我们的医师需要对您的病情有清楚的了解。需要您的医疗记录，包括医师治疗史、手术报告（提供医疗程序的详细情况）。",
        },
        "5": {
            "Question": "谁可以在筋骨还原接受治疗？",
            "Ans": "成人、老人，儿童以及婴儿都可以在筋骨还原接受治疗。决定您是否适合在这接受治疗将取决于您的疾病或诊断，以及其它医疗信息等因素。您可以打电话或WhatsApp联系我们，询问或预约咨询。",
        },
        "6": {
            "Question": "什么是个性化治疗？",
            "Ans": "根据不同的伤情，年龄，身体状况等因素，定制适合您的个性化治疗方案。",
        },
        "7": {
            "Question": "费用是多少？",
            "Ans": "因为每个人的身体结构和症状不同，所以价格不同。筋骨还原以合理的收费标准，提供透明的价格信息，治疗前先让患者能够清晰了解自己需要支付的费用。",
        },
        "8": {
            "Question": "是否能够提供折扣？",
            "Ans": "很遗憾，我们不提供折扣。我们明白，对国际患者来说，预先计划好开支十分重要。我们致力于为您提供最准确的预估费用，避免意外开支。",
        },
        "9": {
            "Question": "我去筋骨还原看病需要带什么文件？",
            "Ans": "您的身份证或护照。",
        },
        "10": {
            "Question": "治疗后我需要注意什么？",
            "Ans": "筋骨还原医师或治疗师提供详细的治疗前和治疗后护理指导，以确保治疗的安全和成功，帮助患者尽快恢复。",
        },
        "11": {
            "Question": "筋骨还原的效果如何？",
            "Ans": "只需配合医师和治疗师康复方案，筋骨还原能够有效治疗伤病，恢复健康，达到预期的治疗效果。",
        },
        "12": {
            "Question": "安全保障如何？",
            "Ans": "筋骨还原需要经过专业医师和治疗师进行操作，患者能够得到专业，安全的治疗。",
        },
    },

    "footer": {
        "Community": "社区",
        "QRCode": {
            "2": "小红书"
        },
        "Address": "地址",
    },
}

export default translationZH;