import React from 'react';
import { Collapse, Divider, Typography } from 'antd';
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
const { Title, Text } = Typography;

function Faq({ faqState }) {
    const { t, i18n } = useTranslation();

    return (
        faqState ? (
            <div className="myFaq py-3 px-2 px-sm-5">
                <Divider className=''></Divider>
                <Title level={3} className='text-center'>{t('faq.Title')}</Title>
                <Collapse accordion size="middle" className='mx-2 mx-sm-5 my-5'>
                    <Panel header={t('faq.1.Question')} key="1">
                        <Text>{t('faq.1.Ans1')}</Text>
                        <Text>{t('faq.1.Ans2')}</Text>
                    </Panel>
                    <Panel header={t('faq.2.Question')} key="2">
                        <p>{t('faq.2.Ans')}</p>
                    </Panel>
                    <Panel header={t('faq.3.Question')} key="3">
                        <p>{t('faq.3.Ans')}</p>
                    </Panel>
                    <Panel header={t('faq.4.Question')} key="4">
                        <p>{t('faq.4.Ans')}</p>
                    </Panel>
                    <Panel header={t('faq.5.Question')} key="5">
                        <p>
                            {t('faq.5.Ans')}
                        </p>
                    </Panel>
                    <Panel header={t('faq.6.Question')} key="6">
                        <p>{t('faq.6.Ans')}</p>
                    </Panel>
                    <Panel header={t('faq.7.Question')} key="7">
                        <p>
                            {t('faq.7.Ans')}
                        </p>
                    </Panel>
                    <Panel header={t('faq.8.Question')} key="8">
                        <p>
                            {t('faq.8.Ans')}
                        </p>
                    </Panel>
                    <Panel header={t('faq.9.Question')} key="9">
                        <p>{t('faq.9.Ans')}</p>
                    </Panel>
                    <Panel header={t('faq.10.Question')} key="10">
                        <p>
                            {t('faq.10.Ans')}
                        </p>
                    </Panel>
                    <Panel header={t('faq.11.Question')} key="11">
                        <p>
                            {t('faq.11.Ans')}
                        </p>
                    </Panel>
                    <Panel header={t('faq.12.Question')} key="12">
                        <p>
                            {t('faq.12.Ans')}
                        </p>
                    </Panel>
                </Collapse>
            </div>
        ) : null
    )
};

export default Faq;