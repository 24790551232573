import React from 'react'
import i18n from "i18next"
import BannerAnim from 'rc-banner-anim'
import QueueAnim from 'rc-queue-anim'
import { TweenOneGroup } from 'rc-tween-one'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Typography, Tabs, } from "antd";
import { importAll } from './usefulScripts';
import { withTranslation } from 'react-i18next';

const { Title, Paragraph, Text } = Typography;

const images = importAll(require.context('./img', false, /case[0-9].jpeg/));

const Element = BannerAnim.Element;



let dataArray = [
    {
        pic: images['case0.jpeg'],
        //width: 450,
        //style: { marginTop: '1rem', },
        style: { maxWidth: 465, },
        //map: 'https://zos.alipayobjects.com/rmsportal/XRfQxYENhzbfZXt.png',
        //color: '#FF4058',
        background: 'rgba(209, 209, 209, 0.0)',
        color: 'rgba(209, 209, 209, 0.05)',
        //background: '#FC1E4F',
    },
    {
        pic: images['case1.jpeg'],
        //width: 350,
        style: { maxWidth: 465, },
        //map: 'https://zos.alipayobjects.com/rmsportal/HfBaRfhTkeXFwHJ.png',
        //color: '#FFF43D',
        //background: '#F6B429',
        color: 'rgb(79, 41, 5, 0.08)',
        background: 'rgb(79, 41, 5, 0.1)',
    },
    {
        pic: images['case2.jpeg'],
        style: { maxWidth: 430, },
        background: 'rgba(209, 209, 209, 0.12)',
        color: 'rgba(209, 209, 209, 0.3)',
    },
    {
        pic: images['case3.jpeg'],
        style: { maxWidth: 400, },
        color: 'rgb(79, 41, 5, 0.08)',
        background: 'rgb(79, 41, 5, 0.05)',
    },
    {
        pic: images['case4.jpeg'],
        style: { maxWidth: 575, },
        background: 'rgba(209, 209, 209, 0.12)',
        color: 'rgba(209, 209, 209, 0.2)',
    },
    {
        pic: images['case5.jpeg'],
        style: { maxWidth: 560, },
        color: 'rgb(79, 41, 5, 0.08)',
        background: 'rgb(79, 41, 5, 0.05)',
    },
];


class DetailSwitchDemo extends React.Component {
    /*static propTypes = {
        className: PropTypes.string,
    };*/

    static defaultProps = {
        className: 'details-switch-demo',
    };

    constructor(props) {
        super(props);
        this.state = {
            showInt: 0,
            delay: 0,
            imgAnim: [
                { translateX: [0, 300], opacity: [1, 0] },
                { translateX: [0, -300], opacity: [1, 0] },
            ],
        };
        this.oneEnter = false;
    }
    componentDidMount() {
        /*document.querySelector(".ant-tabs-content-holder").classList.add("reveal");
        document.querySelector(".ant-tabs-content-holder").classList.add("active");*/
    }
    onChange = () => {
        if (!this.oneEnter) {
            this.setState({ delay: 300 });
            this.oneEnter = true;
        }
    }

    onLeft = () => {
        let showInt = this.state.showInt;
        showInt -= 1;
        const imgAnim = [
            { translateX: [0, -300], opacity: [1, 0] },
            { translateX: [0, 300], opacity: [1, 0] },
        ];
        if (showInt <= 0) {
            showInt = 0;
        }
        this.setState({ showInt, imgAnim });
        this.bannerImg.prev();
        this.bannerText.prev();
    };

    onRight = () => {
        let showInt = this.state.showInt;
        const imgAnim = [
            { translateX: [0, 300], opacity: [1, 0] },
            { translateX: [0, -300], opacity: [1, 0] },
        ];
        showInt += 1;
        if (showInt > dataArray.length - 1) {
            showInt = dataArray.length - 1;
        }
        this.setState({ showInt, imgAnim });
        this.bannerImg.next();
        this.bannerText.next();
    };

    getDuration = (e) => {
        if (e.key === 'map') {
            return 800;
        }
        return 1000;
    };

    onTabChange = (tab) => {
        document.querySelector(".ant-tabs-content-holder").classList.add("fadeIn");
        setTimeout(function () {
            document.querySelector(".ant-tabs-content-holder").classList.remove("fadeIn");
        }, 500);

    }

    render() {
        const { t } = this.props;
        const tabData = [{
            label: i18n.t('product.Concept.Label'),
            key: 1,
            children: (
                <div>
                    <Title level={4}>{i18n.t('product.Concept.Title')}</Title>
                    <Paragraph>
                        {i18n.t('product.Concept.Para1')}
                    </Paragraph>
                    <Paragraph>
                        {i18n.t('product.Concept.Para2')}
                    </Paragraph>
                    <Paragraph>
                        {i18n.t('product.Concept.Para3')}
                    </Paragraph>
                    <Paragraph>
                        {i18n.t('product.Concept.Para4')}
                    </Paragraph>
                </div>
            ),
        },
        {
            label: i18n.t('product.Theory.Label'),
            key: 2,
            children: (
                <div>
                    <Title level={4}>{i18n.t('product.Theory.Title')}</Title>
                    <Paragraph>
                        {i18n.t('product.Theory.Para1')}
                    </Paragraph>
                    <Paragraph>
                        {i18n.t('product.Theory.Para2')}
                    </Paragraph>
                    <Paragraph>
                        {i18n.t('product.Theory.Para3')}
                    </Paragraph>
                    <Paragraph>
                        {i18n.t('product.Theory.Para4')}
                    </Paragraph>
                    <Paragraph>
                        {i18n.t('product.Theory.Para5')}
                    </Paragraph>
                </div>
            ),
        },
        {
            label: i18n.t('product.Uniqueness.Label'),
            key: 3,
            children: (
                <div>
                    <Title level={4}>{i18n.t('product.Uniqueness.Title')}</Title>
                    <Paragraph>
                        <Title level={5}>{i18n.t('product.Uniqueness.SubTitle1')}</Title>
                        {i18n.t('product.Uniqueness.SubTitle1_Para')}
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>{i18n.t('product.Uniqueness.SubTitle2')}</Title>
                        {i18n.t('product.Uniqueness.SubTitle2_Para')}
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>{i18n.t('product.Uniqueness.SubTitle3')}</Title>
                        {i18n.t('product.Uniqueness.SubTitle3_Para')}
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>{i18n.t('product.Uniqueness.SubTitle4')}</Title>
                        {i18n.t('product.Uniqueness.SubTitle4_Para')}
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>{i18n.t('product.Uniqueness.SubTitle5')}</Title>
                        {i18n.t('product.Uniqueness.SubTitle5_Para')}
                    </Paragraph>
                </div>
            ),
        },
        ]
        const textData = [
            {
                content: (
                    <>
                        <Text>{i18n.t('product.cases.1.Content1')}</Text> < br />
                        <Text>{i18n.t('product.cases.1.Content2')}</Text>
                    </>
                ),
                title: i18n.t('product.cases.1.Title'),
            },
            {
                content: (
                    <>
                        <Text>{i18n.t('product.cases.2.Content')}</Text> < br />
                    </>
                ),
                title: i18n.t('product.cases.2.Title'),
            },
            {
                content: (
                    <>
                        <Text>{i18n.t('product.cases.3.Content')}</Text>
                    </>
                ),
                title: i18n.t('product.cases.3.Title'),
            },
            {
                content: (
                    <>
                        <Text>{i18n.t('product.cases.4.Content1')}</Text> < br />
                        <Text>{i18n.t('product.cases.4.Content2')}</Text>
                    </>
                ),
                title: i18n.t('product.cases.4.Title'),
            },
            {
                content: (
                    <>
                        <Text>{i18n.t('product.cases.5.Content1')}</Text> < br />
                        <Text>{i18n.t('product.cases.5.Content2')}</Text>
                    </>
                ),
                title: i18n.t('product.cases.5.Title'),
            },
            {
                content: (
                    <>
                        <Text>{i18n.t('product.cases.6.Content')}</Text> < br />
                    </>
                ),
                title: i18n.t('product.cases.6.Title'),
            },
        ];
        dataArray = dataArray.map((item, index) => ({ ...item, ...textData[index] }));

        const imgChildren = dataArray.map((item, i) => (
            <Element
                key={i}
                style={{
                    background: item.color,
                    height: '100%',
                }}
                leaveChildHide
            >

                <QueueAnim
                    animConfig={this.state.imgAnim}
                    duration={this.getDuration}
                    delay={[!i ? this.state.delay : 300, 0]}
                    ease={['easeOutCubic', 'easeInQuad']}
                    key="img-wrapper"
                >

                    <div className={`${this.props.className}-map map_${i}`} key="map">
                        <img src={item.map} width="100%" alt="" />
                    </div>

                    <div className={`${this.props.className}-pic pic_${i}`} key="pic" style={item.style}>
                        <div className="d-flex flex-row justify-content-around align-items-around fs-4 mb-1" style={{ fontFamily: 'Tinos', fontSize: '30px', letterSpacing: 3 }}>
                            <div>Before</div>
                            <div>After</div>
                        </div>
                        <img src={item.pic} width={'100%'} alt="product_img" className="productImg" />
                    </div>
                </QueueAnim>
            </Element>
        )
        );
        const textChildren = dataArray.map((item, i) => {
            const { title, content, background } = item;
            return (<Element key={i}>
                <QueueAnim type="bottom" duration={1000} delay={[!i ? this.state.delay + 500 : 800, 0]}>
                    <h1 key="h1">{title}</h1>
                    <em key="em" style={{ background }} />
                    <p key="p">{content}</p>
                </QueueAnim>
            </Element>);
        });
        return (
            <>
                <div className="d-flex justify-content-center px-4 reveal" >
                    <Tabs
                        tabPosition='left'
                        items={tabData}
                        onChange={this.onTabChange}
                        className="productTab"
                    />
                </div>

                <div
                    className={`${this.props.className}-wrapper reveal`}
                    style={{ background: dataArray[this.state.showInt].background }}

                >
                    <Title level={4} className="text-center pt-3 pb-0 mb-0 " style={{ textDecoration: 'underline', textUnderlineOffset: 10, textDecorationColor: 'rgba(180, 180, 180, 1.0)' }}>{i18n.t('product.cases.Title')}</Title>
                    <div className='py-0 my-3 py-xs-3'>
                        <div className={`${this.props.className} d-flex flex-column pt-0 mt-0`}>
                            <BannerAnim
                                prefixCls={`${this.props.className}-img-wrapper`}
                                sync
                                type="across"
                                duration={1000}
                                ease="easeInOutExpo"
                                arrow={false}
                                thumb={false}
                                ref={(c) => { this.bannerImg = c; }}
                                onChange={this.onChange}
                                dragPlay={false}
                            >
                                {imgChildren}
                            </BannerAnim>
                            <BannerAnim
                                prefixCls={`${this.props.className}-text-wrapper`}
                                sync
                                type="across"
                                duration={1000}
                                arrow={false}
                                thumb={false}
                                ease="easeInOutExpo"
                                ref={(c) => { this.bannerText = c; }}
                                dragPlay={false}
                            >
                                {textChildren}
                            </BannerAnim>
                            <TweenOneGroup enter={{ opacity: 0, type: 'from' }} leave={{ opacity: 0 }}>
                                {this.state.showInt !== 0 && <LeftOutlined onClick={this.onLeft} style={{ fontSize: '30px' }} className="left-arrow mt-5" />}
                                {this.state.showInt < dataArray.length - 1 && <RightOutlined onClick={this.onRight} style={{ fontSize: '30px' }} className="right-arrow mt-5" />}
                            </TweenOneGroup>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(DetailSwitchDemo);