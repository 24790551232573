const translationEN = {
    "nav": {
        "Home": "Home",
        "Philosophy": "Philosophy",
        "Product": "Services",
        "Reviews": "Reviews",
        "Contact": "Contact",
        "FAQ": "FAQ",
        "PDF": "PDF"
    },
    "philosophy": {
        "Founder": "Founder",
        "Name": "N a m e : Chia Wei Leong",
        "Description1": "RESPECT - LOVE - PRIORITIZE",
        "Description2": "Be thoughtful and attentive",
        "Description3": "are the principles of Efeng Spine Healig.",
        "Description4": "",
        "Para1": "Master Chia, the founder of Efeng Spine Healing and formerly known as Chia Wei Long, hails from Sepang, Selangor, Malaysia. He has gained recognition as a distinguished Chinese medicine practitioner in China, Singapore, and Malaysia. Throughout his upbringing, Master Chia consistently observed the suffering inflicted by illness upon those around him. This led him to understand that when a family member's health deteriorates, the entire family faces hardships.",
        "Para2": "Seeking to become a physician and provide healthcare to all individuals, he made a heartfelt wish. However, financial hardships confronted his family, making it difficult to afford his medical education abroad. Determined, he worked tirelessly and saved money until he had enough to pursue his studies overseas. There, he delved into various disciplines, including chiropractic, massage, acupuncture, and facial rejuvenation techniques. With his medical license in hand, he commenced treating patients, experiencing immense satisfaction in healing many. However, as his patient count grew, he encountered complex and baffling cases. Among a hundred patients, only seventy would recover, leaving him disheartened by the remaining thirty who deteriorated without improvement. Despite feeling powerless, his determination remained unwavering as he wished for their recovery and good health.",
        "Para3_title": "Background",
        "Para3_1": {
            "zh": "However, Master Chia never gave up. He firmly believed that in this vast world, there must be a treatment that can ensure the recovery and good health of every individual. Driven by this belief, he used all his savings to continue studying abroad. Even if it meant giving his all to treat just one patient, he was willing to do so. However, during that time, he faced the overwhelming burden of tuition fees, plunging him into financial difficulties. He could only sustain himself on bread and instant noodles. When his family learned about his pursuit of further education abroad, they opposed it due to financial concerns. Faced with financial challenges and family opposition, he also encountered expensive courses that offered little useful knowledge. He endured the agony of managing living expenses, working during the day, providing treatments at night to earn a livelihood, and utilizing fragmented time for studying. In this manner, his life revolved around work, meals, treatments, and studying, repeatedly. Later, perhaps through the compassion of fate, his luck began to turn. He gradually encountered good teachers and met many renowned doctors. Master Chia expressed gratitude towards his mentors. With the accumulation of extensive clinical experience and guidance from these esteemed physicians, he made a discovery. He realized that each person's body follows a certain pattern, and by restoring the alignment of their muscles and bones to their original positions, most of the acidity, pain, and numbness can be greatly improved. This even includes internal medicine conditions such as ",
            "en_p1": "However, Master Chia remained resolute and unwavering. He firmly held the belief that within this expansive world, there had to be a treatment that could guarantee the recovery and well-being of every individual. Driven by this conviction, he devoted all his savings to pursue further studies abroad. Even if it meant exerting all his efforts to heal just one patient, he was willing to do so. However, during that period, he faced the daunting challenge of exorbitant tuition fees, which plunged him into financial hardship. He could only sustain himself on meager meals of bread and instant noodles. Upon learning of his decision to pursue overseas education, his family expressed opposition due to financial concerns. Confronted with financial constraints and familial resistance, he also encountered courses that charged exorbitant fees without providing substantial knowledge. Enduring the torment of managing living expenses, working during the day, providing treatments at night to earn a living, and utilizing every spare moment for studying, his life revolved around a relentless cycle of work, meals, treatments, and studying.",
            "en_p2": "In spite of that, fortune smiled upon him, perhaps guided by benevolence. He gradually crossed paths with exceptional mentors and encountered renowned doctors. Master Chia expressed profound gratitude towards these guiding figures. With the accumulation of extensive clinical experience and the guidance of these esteemed physicians, he made a groundbreaking discovery. He realized that each person's body follows a distinct pattern, and by restoring the alignment of their muscles and bones to their original positions, significant improvements could be achieved in alleviating acidity, pain, and numbness. This approach even extended to internal medicine conditions such as ",
        },
        "Para3_2": "migraines",
        "Para3_3": "insomnia",
        "Para3_4": "dizziness",
        "Para3_5": "gastric issues",
        "Para3_6": "chest tightness",
        "Para3_7": ", and more, all of which showed potential for recovery. It was truly remarkable. Finally, after relentless dedication and positively impacting the lives of numerous individuals, Master Chia established the ",
        "Para3_8": "Efeng Spine Healing",
        "Para3_9": " therapy in 2015, drawing upon his profound insights and experiences.",
        "Para3_10": "Through a series of accomplishments and experiences, Master Chia's ",
        "Para3_11": "Efeng Spine Healing",
        "Para3_12": " therapy has successfully aided over",
        "Para3_13": " 34,000",
        "Para3_14": " patients in achieving optimal health. Moreover, it has played a pivotal role in fostering harmony within numerous families. Just as the loss of one's health can plunge an entire family into distress, the restoration of good health can bring about happiness and serenity to the entire household. Looking ahead, an even greater number of individuals will undoubtedly reap the benefits of this therapy.",
    },
    "product": {
        "Concept": {
            "Label": "Concept",
            "Title": "Principle of Musculoskeletal Alignment",
            "Para1": "Musculoskeletal Alignment is a rehabilitative therapy method that focuses on the bones, muscles, joints, and other tissues of the human body. Its core principle involves utilizing specific techniques and skills to reestablish the biomechanical equilibrium of the skeletal system, muscles, and joints, resulting in pain alleviation and enhanced recovery.",
            "Para2": "The underlying principle of Musculoskeletal Alignment lies in the interconnectedness of the body's bones, muscles, and joints, which play a vital role in movement and functionality. Imbalances in the biomechanics of these structures can lead to issues such as pain, stiffness, and misalignment, adversely affecting normal bodily movements and functions.",
            "Para3": "The techniques and skills employed in Musculoskeletal Alignment take into account various factors, including body posture, movement patterns, physiological conditions, and more. By addressing specific issues and symptoms, tailored adjustment techniques are applied to restore the biomechanical balance of the bones, muscles, and joints. The ultimate goal is to alleviate pain, improve mobility, and facilitate the process of rehabilitation.",
            "Para4": "Musculoskeletal Alignment utilizes these techniques and skills to restore the biomechanical balance of the body's bones, muscles, and joints, thereby achieving pain relief and promoting recovery.",
        },

        "Theory": {
            "Label": "Theory",
            "Title": "Musculoskeletal Alignment Theory",
            "Para1": "The theory of Musculoskeletal Alignment, specifically, Efeng Spine Healing is a treatment approach for addressing physical discomfort and pain, developed by Master Chia, a physician from Malaysia.",
            "Para2": "According to this theory, the fascia, nerve tissues, ligaments, muscles, and bones throughout the body are interconnected. Any issues within these components can have a widespread impact on the body's overall functioning.",
            "Para3": "Musculoskeletal Alignment theory employs techniques to correct joints, fascia, and spinal bones in different areas of the body, with the goal of improving bodily functions and relieving pain. This method typically involves the use of spinal instruments or manual techniques to apply gentle pressure or stretching to the patient's body.",
            "Para4": "The approach can be utilized for treating various conditions, including cervical spondylosis, lumbar spondylosis, frozen shoulder, arthritis, sciatica, herniated discs, migraines, insomnia, digestive disorders, and more.",
            "Para5": "It is important to highlight that Musculoskeletal Alignment should only be performed by trained professionals, such as certified Musculoskeletal Alignment physicians or therapists, to ensure the effectiveness and safety of the treatment.",
        },

        "Uniqueness": {
            "Label": "Uniqueness",
            "Title": "The uniqueness of Musculoskeletal Alignment",
            "SubTitle1": "1. Non-surgical treatment",
            "SubTitle1_Para": "Musculoskeletal Alignment utilizes spinal instruments or manual techniques for treatment, eliminating the need for surgical intervention and avoiding the risks and painful recovery period associated with surgery.",
            "SubTitle2": "2. Personalized treatment",
            "SubTitle2_Para": "Musculoskeletal Alignment focuses on individualized treatment because each person has unique body structure and issues. Treatment methods need to be tailored accordingly, allowing for personalized and targeted adjustments based on the specific condition of each patient. This personalized approach increases the effectiveness of the treatment.",
            "SubTitle3": "3. No side effects",
            "SubTitle3_Para": "Compared to other forms of treatment such as medication or surgical interventions, the methods used in the theory of Musculoskeletal Alignment are gentle and do not have any side effects. They do not negatively impact the patient's body and are considered safe.",
            "SubTitle4": "4. Quick results",
            "SubTitle4_Para": "Under the theory of Musculoskeletal Alignment, certain conditions can experience rapid relief of symptoms. For example, issues like muscle pain, joint stiffness, and other related symptoms can be alleviated quickly through this form of treatment.",
            "SubTitle5": "5. Wide range of applications",
            "SubTitle5_Para": "Musculoskeletal Alignment is applicable to various conditions, including cervical spondylosis, lumbar spondylosis, frozen shoulder, arthritis, headaches, insomnia, digestive disorders, and more. Therefore, it can be applied in multiple fields to meet the diverse treatment needs of patients.",
        },

        "cases": {
            "Title": "Cases",
            "1": {
                "Title": "Scoliosis",
                "Content1": "Patient: The doctor said according to the X-ray report that I have scoliosis, and the spine requires a surgical cost of MYR 75,500. At that time, the thought of the doctor using a knife to cut me open made me very scared!",
                "Content2": "Later, through a relative's recommendation, I learned about Yi Feng Musculoskeletal Alignment. After undergoing five treatments for scoliosis, my spine has significantly straightened and improved in appearance. I am extremely grateful for Musculoskeletal Alignment therapy, which allowed me to restore a normal body without the need for surgery.",
            },
            "2": {
                "Title": "Kyphosis",
                "Content": "Mother: 'My son has a severe hunchback, with both shoulders inwardly curved and a hunched posture. I worry about him and wonder if he has any orthopedic condition that may result in a lifelong hunchback. After a diagnosis from the doctor, it was confirmed to be a structural hunchback. After just one treatment, the hunchback has significantly improved and straightened.'",
            },
            "3": {
                "Title": "Sciatica",
                "Content": "Due to sciatica causing weakness in the lower back, I was unable to squat without difficulty. After treatment, I can easily squat down without any issues.",
            },
            "4": {
                "Title": "Face Re-Sculpture",
                "Content1": "Throughout my life, I have always felt that my face was very square-shaped. After the physician made adjustments to my lower forehead bone...",
                "Content2": "I have started to like my face more and more. I highly recommend it!",
            },
            "5": {
                "Title": "Frozen Shoulder",
                "Content1": "My hand movements are limited to a certain degree without incurring pain nor could I tie my hair.",
                "Content2": "However, said problems have been treated in one session.",
            },
            "6": {
                "Title": "Knee Arthriti",
                "Content": "My knee has been hurting for a long time, causing pain when walking or sitting. I went to the hospital for an examination, and the doctor recommended surgery, but I declined. Later, a friend recommended me to come here for treatment. Now, my knee is better, and I am extremely happy.",
            }
        }
    },

    "review": {
        "Title": "Efeng Spine Healing",
        "1": {
            "Text1": "I decided to accept the treatment upon a friend's recommendation. The long-term back pain had been preventing me from getting a good night's sleep. The pain significantly improved after a few treatment sessions. Additionally, I have a noticeable improvement from my pelvic pain caused by uneven pelvic alignment where one leg being shorter than the otherdue to a previous fall. Thank you, Master Chia.",
        },
        "2": {
            "Text1": "Upon receiving treatment from Master Chia, my pain issues have significantly reduced. Especially the shoulder soreness, which has improved a lot. My cervical spine, which originally required surgery, has also shown improvement. It is no longer tense and tight. I am extremely grateful to Master Chia for helping me restore a normal daily life.",
            "Text2": "Due to a previous fall, I had an issue with uneven pelvic alignment, resulting in a leg length discrepancy. Prolonged standing caused pelvic pain. However, after treatment, there has been a noticeable improvement. Thank you, Master Chia.",
        },
        "3": {
            "Text1": "I have been in pain for a year! The X-ray report showed misalignment in my L1-L5 vertebrae. I have tried receiving pain injections three times and taking pain medication, but none of them provided relief. Through Facebook, I learned about Master Chia and his Musculoskeletal Alignment therapy. After three treatments, my lower back pain, leg pain, and numbness in the toes have significantly improved.",
            "Text2": "Now, my lower back is no longer sore, and the numbness in my legs and toes has disappeared. Previously, I would experience soreness and numbness after sitting for just half an hour, but now I can sit for extended periods without any issues. I am extremely grateful to Master Chia for allowing me to regain a normal life free from pain and suffering.",
        },
        "4": {
            "Text1": "After the physician's treatment, I no longer feel any pulling or tension in my muscles and bones. My family and friends have noticed that my body has become much straighter, and I no longer have a tendency to lean forward. I can also fully extend my legs, whereas before they were restricted. Previously, I had to curl up my body to fall asleep, but now I can comfortably sleep on my back. The physician's treatment techniques are highly recommended, and I am grateful for their care and treatment.",
        },
    },

    "contact": {
        "Title": "Musculoskeletal Alignment Treatment Process",

        "Consultation": "Consultation",
        "Consultation_Descript": "Click on WhatsApp, and our professional consultant will provide more detailed information.",

        "Appointment": "Appointment",
        "Appointment_Descript1": "Arranging an appointment with the physician for diagnosis",
        "Appointment_Descript2": "Quick responses",

        "Diagnosis": "Diagnosis",
        "Diagnosis_Descript": "To inquire your medical condition, undergo a health examination, or receive a diagnosis of the illness.",

        "Explanation": "Briefing",
        "Explanation_Descript": "To provide detailed explanations to the patient regarding their overall health condition and the process of Musculoskeletal Alignment treatment.",

        "Cost": "Cost",
        "Cost_Descript": "To provide the patient a personalized treatment plan and with a clear understanding of the associated payment fees. Offer fair and transparent pricing information.",

        "Treatment": "Treatment",
        "Treatment_Descript": "Based on the patient's condition and diagnosis results, the physician will customize a personalized treatment plan tailored to their unique body structure and issues. This approach aims to achieve the desired treatment outcomes.",

        "Follow_up": "Follow-up",
        "Follow_up_Descript": "Based on the treatment outcomes and patient response, follow-up observations and treatments are conducted, which may include adjusting the treatment plan, providing rehabilitation guidance and advice, and monitoring the progress of the patient's condition.",
    },

    "faq": {
        "Title": "Frequently Asked Questions",
        "1": {
            "Question": "Do I need an appointment for a medical consultation?",
            "Ans1": "Yes, you need to make an appointment. Please call or reach out to us on WhatsApp to schedule an appointment. We will assign a consultant who will assist you throughout the appointment process.",
            "Ans2": "The assigned consultant will ensure all the necessary examinations and procedures you require are arranged.",
        },
        "2": {
            "Question": "How long does it take to make an appointment?",
            "Ans": "As soon as possible. Your appointment date will depend on the availability of the Efeng Spine physician and your needs.",
        },
        "3": {
            "Question": "Could I request for a specific physician?",
            "Ans": "Yes, you are welcome to request an appointment with a specific physician. Rest assured that the specialist will provide you with authoritative professional expertise for your condition.",
        },
        "4": {
            "Question": "Do I need to provide my medical records to Efeng Spine Healing?",
            "Ans": "Yes, our physicians need a clear understanding of your medical condition. We require your medical records, including the history of treatments received from other physicians and any surgical reports that provide detailed information about medical procedures performed.",
        },
        "5": {
            "Question": "Who could receive treatment at Efeng Spine Healing Center?",
            "Ans": "Adults, seniors, children, and infants. The factors involve your specific conditions or diagnosis, as well as other medical information. Feel free to call or reach out to us on WhatsApp to inquire or schedule a consultation.",
        },
        "6": {
            "Question": "What is a personalized treatment?",
            "Ans": "It is a customized plan based on factors such as the severity of the injury, age, physical condition, and other considerations, a personalized treatment plan will be tailored to suit your specific needs.",
        },
        "7": {
            "Question": "How much does it costs?",
            "Ans": "Due to the variations in each individual's body structure and symptoms, the prices may differ. Efeng Spine adheres to fair pricing standards and provides transparent pricing information, ensuring that patients have a clear understanding of the fees they need to pay before undergoing treatment.",
        },
        "8": {
            "Question": "Could you provide any discounts?",
            "Ans": "Unfortunately, we do not offer any discounts. We understand that planning expenses is crucial for international patients. We are committed to providing you with the most accurate cost estimates to avoid any unexpected expenses.",
        },
        "9": {
            "Question": "What documents do I need to bring when going to Efeng Spine for a medical consultation?",
            "Ans": "Your identification card or passport.",
        },
        "10": {
            "Question": "What should I pay attention to after treatment?",
            "Ans": "Efeng Spine physicians or therapists will provide a set of detailed pre-treatment and post-treatment care instructions to ensure the safety and success of the treatment and to help patients recover as quickly as possible.",
        },
        "11": {
            "Question": "What are the effects of Efeng Spine Healing treatment?",
            "Ans": "By adhering to the physician and therapist's rehabilitation program, Efeng Spine Healing treatment can effectively address injuries, promote healing, and achieve the desired treatment outcomes.",
        },
        "12": {
            "Question": "How is safety ensured?",
            "Ans": "Musculoskeletal Alignment, specifically, Efeng Spine Healing treatment is performed by professional physicians and therapists, ensuring that patients receive professional and safe treatment.",
        },
    },

    "footer": {
        "Community": "Community",
        "QRCode": {
            "2": "LITTLE RED BOOK"
        },
        "Address": "Address",
    },
}

export default translationEN;