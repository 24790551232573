import React from 'react';
import { SolutionOutlined, CustomerServiceOutlined, ScheduleOutlined, CommentOutlined, DollarOutlined, SyncOutlined, AimOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { Typography, Steps, } from 'antd';
import { useTranslation } from "react-i18next";
import { useInterval } from './usefulScripts';
const { Text } = Typography;

function Contact() {
    const { t, i18n } = useTranslation();
    const [current, setCurrent] = React.useState(0);

    const onChange = (value) => {
        setCurrent(value);
    };
    useInterval(() => {
        if (current === 6) {
            setCurrent(0);
        } else {
            setCurrent(current + 1);
        }
    }, 3000);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center pt-2 reveal">
            ————————————<Text className="fs-6" italic={true}> {t('contact.Title')} </Text>————————————
            <Steps
                direction='vertical'
                //type='navigation'
                current={current}
                onChange={onChange}
                className="mt-3"
                style={{ marginLeft: 'auto', marginRight: 'auto', width: 300, }}
                items={[
                    {
                        title: t('contact.Consultation'),
                        description: t('contact.Consultation_Descript'),
                        icon: <CustomerServiceOutlined />,
                    },
                    {
                        title: t('contact.Appointment'),
                        description: (
                            <>
                                {t('contact.Appointment_Descript1')}<br />{t('contact.Appointment_Descript2')}
                            </>
                        ),
                        //subTitle: 'Left 00:00:08',
                        icon: <ScheduleOutlined />,
                    },
                    {
                        title: t('contact.Diagnosis'),
                        description: t('contact.Diagnosis_Descript'),
                        icon: <SolutionOutlined />,
                    },
                    {
                        title: t('contact.Explanation'),
                        description: t('contact.Explanation_Descript'),
                        icon: <CommentOutlined />,
                    },
                    {
                        title: t('contact.Cost'),
                        description: t('contact.Cost_Descript'),
                        icon: <DollarOutlined />,
                    },
                    {
                        title: t('contact.Treatment'),
                        description: t('contact.Treatment_Descript'),
                        icon: <AimOutlined />,
                    },
                    {
                        title: t('contact.Follow_up'),
                        description: t('contact.Follow_up_Descript'),
                        icon: <FieldTimeOutlined />,
                    },
                ]}
            />
        </div>
    )
};

export default Contact;