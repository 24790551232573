import footer_img from './img/banner.jpeg';
import { FacebookFilled, YoutubeFilled, EnvironmentFilled, WhatsAppOutlined, } from '@ant-design/icons';
import { Divider, Typography, Button } from "antd";
import { useTranslation } from "react-i18next";
import WazeIcon from "./img/WazeIcon.png";
import { importAll } from './usefulScripts';

const { Title, Text, } = Typography;

const images = importAll(require.context('./img', false, /qrcode_[0-9].jpeg/));

function MyFooter() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-start justify-content-xxl-between align-items-start flex-wrap pt-3">
                <div className="d-flex flex-column justify-content-between mb-4 pe-xxl-1 pe-5 me-xxl-1 me-5">
                    <div className="d-flex flex-column align-items-start text-nowrap">
                        <Title level={5} className="pb-2">{t('footer.Community')}</Title>
                        <div><FacebookFilled className="pe-1 pb-3" /> <a href='https://www.facebook.com/EfengSpineHealingCenter/' style={{ textDecoration: 'none', color: 'black', letterSpacing: 0.35 }}>Facebook</a></div>
                        <div><YoutubeFilled className="pe-1 pb-3" /> <a href='https://www.youtube.com/channel/UCKFNBxVQG8_oThb_SWQ17EA' style={{ textDecoration: 'none', color: 'black', letterSpacing: 0.35 }}>Youtube</a></div>
                        <div><WhatsAppOutlined className="pe-1 pb-3" /> <a href='https://api.whatsapp.com/send?phone=60133237828&text=Hi,%20I%20would%20like%20to%20know%20more!' className="whatsAppURL" style={{ textDecoration: 'none', color: 'black', letterSpacing: 0.2 }}>+60 13-3237828</a></div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start text-nowrap pb-5 pe-0">
                    <div className="d-flex flex-column pb-4 pe-5">
                        <img
                            src={images['qrcode_3.jpeg']}
                            alt="WHATSAPP"
                            style={{ width: '7.5rem' }}
                            className="mb-1"
                        />
                        <Text strong={true}>WHATSAPP</Text>
                    </div>
                    <div className="d-flex flex-column pb-4 pe-5">
                        <img
                            src={images['qrcode_1.jpeg']}
                            alt="小红书二维码"
                            style={{ width: '6.8rem' }}
                            className="mb-1"
                        />
                        <Text strong={true}>{t('footer.QRCode.2')}</Text>
                    </div>
                    <div className="d-flex flex-column pb-4">
                        <img
                            src={images['qrcode_2.jpeg']}
                            alt="TIKTOK"
                            style={{ width: '6.9rem' }}
                            className="mb-1"
                        />
                        <Text strong={true}>TIKTOK</Text>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                    <div className="d-flex flex-column align-items-start pe-3 pb-5">
                        <Title level={5} className="pb-2">{t('footer.Address')}</Title>
                        <div className="d-flex flex-row justify-content-around align-items-start mb-3">
                            <EnvironmentFilled className="pe-4 pt-1" />
                            <div className="text-start" style={{ width: 250 }} > <a href='https://goo.gl/maps/rn85HSadNXTyCwiT6' className="text-wrap" style={{ textDecoration: 'none', color: 'black', letterSpacing: 0.35, }}>NO:22-G JALAN ECO ARDENCE CU12/36C, ECO ARDENCE CREST, 40170 SHAH ALAM SELANGOR, SHAH ALAM, MALAYSIA</a></div>
                        </div>
                        <Button type="primary ps-2" shape="round" style={{ height: 34 }}>
                            <a href='https://ul.waze.com/ul?place=ChIJYQLntzlTzDERtWW5PyAMcp8&ll=3.08817540%2C101.47716560&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location' className="" style={{ textDecoration: 'none', letterSpacing: 0.35, fontWeight: 500, }}><img src={WazeIcon} className="me-2 mb-1" style={{ width: 22.5, }} />Open In Waze</a>
                        </Button>
                    </div>

                    <img src={footer_img} width="300" className="footer_img" />
                </div>

            </div >

            <Divider />
            <div>EFENG SPINE HEALING CENTER ©{new Date().getFullYear()}</div>
        </>
    )
}

export default MyFooter;