import React from 'react';
import { Row, Col, Typography, Divider, } from 'antd';
import { useTranslation } from "react-i18next";
import ProfilePic from './img/profile_image.jpeg';

const { Title, Paragraph, Text, } = Typography;

function slideInLeft() {
    var reveals = document.querySelectorAll(".slideInLeft");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;

        var elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
            var elementBottom = reveals[i].getBoundingClientRect().bottom;

            if (elementBottom <= 0) {
                reveals[i].classList.remove("slideActive");
            } else {
                reveals[i].classList.add("slideActive");
            }
        } else {
            reveals[i].classList.remove("slideActive");
        }
    }
}

function Philosophy({ lang }) {
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        window.addEventListener("scroll", slideInLeft, { passive: true });
        slideInLeft();
        return () => window.removeEventListener('scroll', slideInLeft);
    }, [])

    return (
        <>
            <Row justify="center" className='px-0 px-md-2 px-xl-5'>
                <Col flex="300px" className='mb-3 philosophy_img_wrapper'>
                    <div className='philosophy_img' style={{ backgroundImage: `url("${ProfilePic}")` }}>
                    </div>
                </Col>

                <Col flex="400px" className='mb-3' >
                    <div className="ps-4  slideInLeft" style={{ letterSpacing: 7 }}>
                        <Title level={2} className="philosophy_title pt-3 mb-3 pb-1">
                            {t('philosophy.Founder')}
                        </Title>
                        <Title level={5} className="py-0 my-0" style={{ letterSpacing: 5 }}>
                            {t('philosophy.Name')}
                            <div style={{ textIndent: 81 }} >
                                MASTER CHIA
                            </div>
                        </Title>
                    </div>


                    <div className="ps-4 mt-3 pt-4 pb-3" style={{ backgroundColor: 'rgb(159, 146, 72)' }}>
                        <Title level={3} className="philosophy_text mb-4 slideInLeft">
                            {t('philosophy.Description1')}
                        </Title>

                        <Text className="slideInLeft" style={{ color: "black" }}>
                            {t('philosophy.Description2')} <br />
                            {t('philosophy.Description3')}<br />
                            {t('philosophy.Description4')}
                        </Text>
                    </div>

                </Col>

            </Row >
            <Row justify="center" className='px-2 px-md-2 px-xl-5 reveal'>
                <Col className='philosophy_intro mb-3' style={{ width: '55vw' }}>
                    <Divider orientation="left" plain><Title level={3}>{t('philosophy.Para3_title')}</Title></Divider>
                    <Paragraph ellipsis={{ rows: 8, expandable: true, symbol: '更多' }} className="reveal">
                        <div style={{ textIndent: '2rem' }}>
                            {t('philosophy.Para1')}
                        </div>
                        <br />
                        <div style={{ textIndent: '2rem' }}>
                            {t('philosophy.Para2')}
                        </div>
                        <br />
                        <div style={{ textIndent: '2rem' }}>
                            {lang === 'zh' ?
                                t('philosophy.Para3_1.zh') :
                                <>

                                    {t('philosophy.Para3_1.en_p1')}
                                    <br />
                                    <br />
                                    <span style={{ textIndent: '2rem' }}>{t('philosophy.Para3_1.en_p2')}</span>
                                </>
                            }
                            <Text code>{t('philosophy.Para3_2')}</Text>，<Text code>{t('philosophy.Para3_3')}</Text>，<Text code>{t('philosophy.Para3_4')}</Text>，<Text code>{t('philosophy.Para3_5')}</Text>，<Text code>{t('philosophy.Para3_6')}</Text>
                            {t('philosophy.Para3_7')}
                            <Text code>{t('philosophy.Para3_8')}</Text>{t('philosophy.Para3_9')}
                        </div>
                        <br />
                        <div style={{ textIndent: '2rem' }}>
                            {t('philosophy.Para3_10')}
                            <Text code>{t('philosophy.Para3_11')}</Text>{t('philosophy.Para3_12')}<Text strong>{t('philosophy.Para3_13')}</Text>{t('philosophy.Para3_14')}
                        </div>
                    </Paragraph>
                </Col>
            </Row>

        </>
    )
};

export default Philosophy;