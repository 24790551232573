import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { useTranslation } from "react-i18next";
import Philosophy from './Philosophy';
import Contact from './Contact';
import DetailSwitchDemo from './Product';
import Review from './Review';
import Faq from './Faq';
import MyFooter from './Footer.js';
import React from 'react';
import { HomeOutlined, ShopOutlined, ReadOutlined, FormOutlined, ProfileOutlined, DownloadOutlined, WhatsAppOutlined, EyeOutlined, EyeInvisibleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Menu, Carousel, Image, theme, Typography, Divider, Affix, Button, Switch } from "antd";
import { importAll, useInterval } from './usefulScripts';
import whatsAppIcon from './img/WhatsApp.svg';

// Destructuring
const { Header, Content, Footer } = Layout;
const { Title, } = Typography;

const images = importAll(require.context('./img', false, /Banner_[0-9].jpeg/));

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;

        var elementVisible = 100;
        //console.log('windowHeight:', windowHeight);
        //console.log('elementTop:', elementTop);
        if (elementTop < windowHeight - elementVisible) {
            //var elementBottom = reveals[i].getBoundingClientRect().bottom;
            //console.log('windowHeight:', windowHeight, 'elementTop:', elementTop, elementBottom);
            reveals[i].classList.add("active");
            /*
            if (elementBottom <= 0) {
                reveals[i].classList.remove("active");
            } else {
                reveals[i].classList.add("active");
            }*/
        } else {
            reveals[i].classList.remove("active");
        }
    }
}

function MyLayout({ currentTheme, changeTheme, configCSSManually }) {
    const { token } = theme.useToken();
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState(i18n.language);

    const handleLangChange = () => {
        if (lang === 'en') {
            i18n.changeLanguage("zh");

            setLang('zh');
        } else {
            i18n.changeLanguage("en");
            setLang('en');
        }
    }
    const [current, setCurrent] = React.useState('1');

    // ----------Affix States----------
    const [bottom, setBottom] = React.useState(25);
    const [affixAnim, setAffixAnim] = React.useState(null);

    const affixOnClick = () => {
        // Affix Animation
        if (affixAnim === null) {
            setAffixAnim("scaleUp");
        } else {
            setAffixAnim(null);
        }
        // Open whatsApp
        setTimeout(function () {
            document.querySelector(".whatsAppURL").click()
        }, 500);
    }

    // ----------Menu States----------
    const [menu, setMenu] = React.useState('1');
    const SiderItems = [
        {
            key: String(1),
            icon: React.createElement(HomeOutlined),
            label: t('nav.Home'),
            className: 'list nav_home'
        },
        {
            key: String(2),
            icon: React.createElement(ReadOutlined),
            label: t('nav.Philosophy'),
            className: 'list nav_philosophy'
        },
        {
            key: String(3),
            icon: React.createElement(ShopOutlined),
            label: t('nav.Product'),
            className: 'list nav_product'
        },
        {
            key: String(4),
            icon: React.createElement(ProfileOutlined),
            label: t('nav.Reviews'),
            className: 'list nav_reviews'
        },
        {
            key: String(5),
            icon: React.createElement(FormOutlined),
            label: t('nav.Contact'),
            className: 'list nav_contact'
        },
        {
            key: String(6),
            icon: React.createElement(QuestionCircleOutlined),
            label: t('nav.FAQ'),
            className: 'list nav_faq'
        },
        {
            key: String(7),
            icon: React.createElement(DownloadOutlined),
            label: t('nav.PDF'),
            className: 'list nav_download'
        },
    ];
    React.useEffect(() => {
        let section = document.querySelectorAll('.mySection');
        let lists = document.querySelectorAll('.list');

        function activeLink(li) {
            lists.forEach((item) => {
                item.classList.remove('ant-menu-item-active');
                item.classList.remove('ant-menu-item-selected')
            });
            li.classList.add('ant-menu-item-active');
            li.classList.add('ant-menu-item-selected');
        }

        window.onscroll = () => {
            section.forEach(sec => {
                let top = window.scrollY;
                let offset = sec.offsetTop;
                let height = sec.offsetHeight;
                let id = sec.getAttribute('id');

                if (top >= offset && top < offset + height) {
                    //const target = document.querySelector(`[href='#${id}']`).parentElement;
                    const target = document.querySelector(`.nav_${id}`);
                    activeLink(target);
                }
            })
        };

        let menuIcon = document.querySelector('.anticon-ellipsis');
        // Replace menuIcon svg to <MenuOutlined />
        menuIcon.childNodes[0].childNodes[0].setAttribute('d', "M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z");

        window.addEventListener("scroll", reveal, { passive: true });
        reveal();
        return () => window.removeEventListener('scroll', reveal);
    }, []);


    const [test, setTest] = React.useState(1);
    useInterval(() => {
        var element = document.querySelector(".details-switch-demo-pic");
        const index = element.classList[1].split("_")[1];

        var rightArrow = document.querySelector(".right-arrow");
        var leftArrow = document.querySelector(".left-arrow");
        if (rightArrow !== null && test === 1) {
            rightArrow.click();
        } else if (leftArrow !== null && test === -1) {
            leftArrow.click();
        }
        if (parseInt(index) === 5) {
            setTest(-1);
        } else if (parseInt(index) === 0) {
            setTest(1);
        }
    }, 8000);

    const menuOnSelect = (values) => {
        setMenu(values.key);
    };
    const menuOnClick = (e) => {
        setCurrent(e.key);
        // Scroll to the active menu section when the menu is clicked
        switch (e.key) {
            case '1':
                document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
                break;
            case '2':
                document.getElementById('philosophy').scrollIntoView({ behavior: 'smooth' });
                break;
            case '3':
                document.getElementById('product').scrollIntoView({ behavior: 'smooth' });
                break;
            case '4':
                document.getElementById('reviews').scrollIntoView({ behavior: 'smooth' });
                break;
            case '5':
                document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
                break;
            case '6':
                document.getElementById('faq').scrollIntoView({ behavior: 'smooth' });
                setFaqState(true);
                break;
            case '7':
                fetch('筋骨还原手册.pdf').then(response => {
                    response.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = '筋骨还原手册.pdf';
                        alink.click();
                    })
                })
                break;
            default:
                document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
        }
    };



    // ----------FAQ States----------
    const [faqState, setFaqState] = React.useState(false);
    React.useEffect(() => {
        //if (faqState) {

        //}
        // Faq animation
        var faq = document.querySelector(".myFaq");
        if (faq !== null) {
            if (faqState) {
                faq.classList.add("bottomIn");
            } else {
                faq.classList.remove("bottomIn");
            }
        }
    })
    const faqOnClick = (e) => {
        setFaqState(!faqState);
        document.getElementById('faq').scrollIntoView({ behavior: 'smooth' });
    }

    const CarouselContentStyle = {
        maxHeight: '85vh',
        //width: '120%',
        //color: '#fff',
        //lineHeight: '160px',
        //textAlign: 'center',
        //background: '#364d79',
    };

    return (
        <Layout className="layout">
            <Header className="" style={{ background: token.colorBgContainer, position: 'sticky', top: 0, zIndex: 1, width: '100%', height: 'auto', paddingInline: 0, boxShadow: '0px 0.5px 1.5px 0px grey' }}>
                <div className="container">
                    <div className="row">
                        <div className="px-0 mx-0" >
                            <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
                                <div className="logo d-flex flex-row justify-content-around align-items-center flex-wrap text-nowrap my-1 ps-3" style={{ fontSize: '1.1rem', lineHeight: '34px' }}>
                                    <div className="HeaderTitle gold">
                                        <span style={{ fontSize: '27px', letterSpacing: 1 }}>E</span>FENG <span style={{ fontSize: '27px', letterSpacing: 1 }}>S</span>PINE <span style={{ fontSize: '27px', letterSpacing: 1 }}>H</span>EALING <span style={{ fontSize: '27px', letterSpacing: 1 }}>C</span>ENTER
                                    </div>
                                    <div className="HeaderTitle gold fw-bold ms-3" style={{ fontSize: '1.25rem', letterSpacing: 3 }}>易峰筋骨还原</div>
                                </div>
                                <div className="d-flex flex-row justify-content-around align-items-center">
                                    <Menu
                                        className="pe-2"
                                        theme={currentTheme === theme.darkAlgorithm ? 'dark' : 'light'}
                                        mode="horizontal"
                                        defaultSelectedKeys={['1']}
                                        selectedKeys={[current]}
                                        items={SiderItems}
                                        onSelect={menuOnSelect}
                                        onClick={menuOnClick}
                                        style={{ maxWidth: 350 }}
                                    />
                                    <div className="mb-1" ><Switch checkedChildren="EN" unCheckedChildren="CH" checked={lang === 'en' ? true : false} onClick={handleLangChange} /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Header >

            <Layout>
                <Content style={{ padding: '1px 0px' }}>
                    <div style={{ background: token.colorBgContainer }}>
                        <section id="home" className='mySection'>
                            <Carousel autoplay adaptiveHeight={true}>
                                <div>
                                    <div style={CarouselContentStyle}>
                                        <Image
                                            preview={false}
                                            src={images["Banner_1.jpeg"]}
                                            width={'100vw'}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div style={CarouselContentStyle}>
                                        <Image
                                            preview={false}
                                            src={images["Banner_2.jpeg"]}
                                            width={'100vw'}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div style={CarouselContentStyle}>
                                        <Image
                                            preview={false}
                                            src={images["Banner_3.jpeg"]}
                                            width={'100vw'}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div style={CarouselContentStyle}>
                                        <Image
                                            preview={false}
                                            src={images["Banner_4.jpeg"]}
                                            width={'100vw'}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div style={CarouselContentStyle}>
                                        <Image
                                            preview={false}
                                            src={images["Banner_5.jpeg"]}
                                            width={'100vw'}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div style={CarouselContentStyle}>
                                        <Image
                                            preview={false}
                                            src={images["Banner_6.jpeg"]}
                                            width={'100vw'}
                                        />
                                    </div>
                                </div>
                            </Carousel>
                        </section>

                        <section id="philosophy" className='mySection'>
                            <Divider><Title level={3}>{t('nav.Philosophy')}</Title></Divider>
                            <Philosophy lang={lang} />
                        </section>

                        <section id="product" className='mySection'>
                            <Divider><Title level={3}>{t('nav.Product')}</Title></Divider>
                            <DetailSwitchDemo />
                        </section>

                        <section id="reviews" className='mySection'>
                            <Divider><Title level={3}>{t('nav.Reviews')}</Title></Divider>
                            <Review />
                        </section>

                        <section id="contact" className='mySection'>
                            <Divider className='mb-0 pb-0'><Title level={3} className='mb-0 pb-0'>{t('nav.Contact')}</Title></Divider>
                            <Contact />
                        </section>

                        <section id="faq" className='mySection'>
                            <div className='text-center pb-2'>
                                <button onClick={faqOnClick} className="btn btn-sm btn-outline-primary ms-2">
                                    <div className='d-flex flex-row justify-content-around align-items-center flex-nowrap'>
                                        FAQ {faqState ? (<EyeOutlined className="ps-2" />) : (<EyeInvisibleOutlined className="ps-2" />)
                                        }
                                    </div>
                                </button>
                            </div>
                            <Faq faqState={faqState} />
                        </section>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center', }}>
                    <MyFooter />
                </Footer>
                <Affix offsetBottom={bottom}>
                    <button className={"whatsAppButton mb-3 ms-1 ms-sm-3 " + affixAnim} onClick={affixOnClick}>
                        <img src={whatsAppIcon} className="whatsAppImg" />
                    </button>
                </Affix>
            </Layout>
        </Layout >
    );
};
export default MyLayout;