import { Carousel } from 'bootstrap'
import React from 'react';
import { useTranslation } from "react-i18next";
import { Avatar, List, Typography, Rate, Divider } from 'antd';
import { importAll } from './usefulScripts';

const { Title, Text, } = Typography;

const images = importAll(require.context('./img', false, /Review_[0-9].jpeg/));

/*const data = Array.from({ length: 23 }).map((_, i) => ({
    href: 'https://ant.design',
    title: `ant design part ${i}`,
    avatar: `https://joesch.moe/api/v1/random?key=${i}`,
    description:
        'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    content:
        'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
}));*/



function Review() {
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        // Activate carousel auto play
        const carousel = new Carousel('#carouselExampleInterval');
    }, []);

    const data2 = [
        {
            title: "TYNEE THAM",
            avatar: images['Review_0.jpeg'],
            content: (
                <>
                    <Text>{t('review.1.Text1')}</Text>
                </>
            ),
        },
        {
            title: "KAREN ONG",
            avatar: images['Review_1.jpeg'],
            content: (
                <>
                    <Text>{t('review.2.Text1')}</Text>
                    <Text>{t('review.2.Text2')}</Text>
                </>
            ),
        },
        {
            title: "LAU LAU",
            avatar: images['Review_2.jpeg'],
            content: (
                <>
                    <Text>{t('review.3.Text1')}</Text>
                    <Text>{t('review.3.Text2')}</Text>
                </>
            ),
        },
        {
            title: "巫式祥",
            avatar: images['Review_3.jpeg'],
            content: (
                <>
                    <Text>{t('review.4.Text1')}</Text>
                </>
            ),
        },
    ];

    return (
        <>
            <div id="carouselExampleInterval" className="carousel carousel-dark slide fixHeight reveal" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner py-5 px-5">
                    <div className="carousel-item active px-sm-5 mb-4" data-bs-interval="2000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[0].avatar} style={{ height: '200px', width: '200px', }} />} />
                            </div>

                            <div>
                                <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">{data2[0].title}</Title>
                                        <div className='d-flex flex-row flex-wrap'>
                                            <Text italic={true} className="text-nowrap me-2">recommends</Text>
                                            <Text strong={true} className="text-nowrap">{t('review.Title')}</Text>
                                        </div>

                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className='pt-0 mt-2' />
                                <div className='d-flex flex-column justify-content-around'>
                                    {data2[0].content}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item px-sm-5 mb-4" data-bs-interval="3000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[1].avatar} style={{ height: '200px', width: '200px', }} />} />
                            </div>

                            <div>
                                <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">{data2[1].title}</Title>
                                        <div className='d-flex flex-row flex-wrap'>
                                            <Text italic={true} className="text-nowrap me-2">recommends</Text>
                                            <Text strong={true} className="text-nowrap">{t('review.Title')}</Text>
                                        </div>

                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className='pt-0 mt-2' />
                                <div className='d-flex flex-column justify-content-around'>
                                    {data2[1].content}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item px-sm-5 mb-4" data-bs-interval="2000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[2].avatar} style={{ height: '200px', width: '200px', }} />} />
                            </div>

                            <div>
                                <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">{data2[2].title}</Title>
                                        <div className='d-flex flex-row flex-wrap'>
                                            <Text italic={true} className="text-nowrap me-2">recommends</Text>
                                            <Text strong={true} className="text-nowrap">{t('review.Title')}</Text>
                                        </div>
                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className='pt-0 mt-2' />
                                <div className='d-flex flex-column justify-content-around'>
                                    {data2[2].content}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item px-sm-5 mb-4" data-bs-interval="3000">
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0 me-4">
                                <List.Item.Meta avatar={<Avatar src={data2[3].avatar} style={{ height: '200px', width: '200px', }} />} />
                            </div>

                            <div>
                                <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">{data2[3].title}</Title>
                                        <div className='d-flex flex-row flex-wrap'>
                                            <Text italic={true} className="text-nowrap me-2">recommends</Text>
                                            <Text strong={true} className="text-nowrap">{t('review.Title')}</Text>
                                        </div>
                                    </div>
                                    <Rate disabled defaultValue={5} className="d-flex flex-row flex-nowrap" />
                                </div>
                                <Divider className='pt-0 mt-2' />
                                <div className='d-flex flex-column justify-content-around'>
                                    {data2[3].content}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div >

            {/*<List
                className='px-5'
                itemLayout="horizontal"
                size="large"
                /*pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 4,
                }}
                dataSource={data2}
                /*footer={
                    <div>
                        <b>ant design</b> footer part
                    </div>
                }
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                    /*actions={[
                        <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                        <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                        <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                    ]}*/
                    /*extra={
                        <img
                            width={272}
                            alt="logo"
                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                        />
                    }
                    >
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center px-2 mx-2 px-md-3 mx-md-3 px-xl-5 mx-xl-5">
                            <div className="pb-4 pb-md-0">
                                <List.Item.Meta
                                    avatar={<Avatar src={item.avatar} style={{ height: '200px', width: '200px', }} />}
                                //title={<Title level={5}>{item.title}</Title>}
                                //description={item.description}
                                />
                            </div>

                            <div>
                                <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center'>
                                    <div>
                                        <Title level={5} className="text-nowrap p-0 m-0">{item.title}</Title>
                                        <Text italic={true} className="me-2">recommends</Text><Text strong={true}>易峰筋骨还原</Text>
                                    </div>
                                    <Rate disabled defaultValue={5} />
                                </div>
                                <Divider className='pt-0 mt-2' />
                                <div className='d-flex flex-column justify-content-around'>
                                    {item.content}
                                </div>
                            </div>
                        </div>
                    </ List.Item>
                )}
            />*/}
        </>
    )
};

export default Review;